import Slider from "react-slick/lib/slider";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export function GoldBlock(){
    var settings = {
        dots: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        pauseOnHover: true
      };

    return (
        <div className="gold-block block" id="gold">
            <div className="about-block">
                <h2>Вечер в улицу</h2>
                <p>Что мы знаем о черниковке? Гопники, плохой асфальт (не считая первомайки), машинка, скейтпарк, восьмиэтажка? А что, если посмотреть со стороны архитектуры? Так и посмотрим! <br /><br />Прокатимся по дворам черниковки. Удивимся, что там не просто есть асфальт, а он вполне себе годный. Насладимся архитектурой и вайбом из детства. Возможно даже заблудимся в лабиринтах черниковки!</p>
                <div className="info" style={{
                    borderLeft: '4px solid black'
                }}>
                    <div>Место: <span style={{
                        fontWeight: 'bold'
                    }}><a href="https://go.2gis.com/f1m0m" target="_blank" style={{
                        color: 'black'
                    }}>Фонтан "Часы"</a></span></div>
                    <div>Время сбора: <span style={{
                        fontWeight: 'bold'
                    }}>21 сентября 17:30</span>
                    </div>
                    <div>Время старта: <span style={{
                        fontWeight: 'bold'
                    }}>21 сентября 18:00</span>
                    </div>
                    <div>Сложность: <span style={{
                        fontWeight: 'bold'
                    }}>3/5</span>
                    </div>
                    <div>Расстояние: <span style={{
                fontWeight: 'bold'
            }}>21 км</span>
            </div>
                </div>
                <div className="slider-container">
                    <Slider className="slider" {...settings}>
                        <div>
                            <img src="./images/slider/1.webp" />
                        </div>
                        <div>
                            <img src="./images/slider/2.webp" />
                        </div>
                        <div>
                            <img src="./images/slider/3.webp" />
                        </div>
                        <div>
                            <img src="./images/slider/4.webp" />
                        </div>
                        <div>
                            <img src="./images/slider/5.webp" />
                        </div>
                        <div>
                            <img src="./images/slider/6.webp" />
                        </div>
                        <div>
                            <img src="./images/slider/7.webp" />
                        </div>
                        <div>
                            <img src="./images/slider/8.webp" />
                        </div>
                        <div>
                            <img src="./images/slider/9.webp" />
                        </div>
                        <div>
                            <img src="./images/slider/10.webp" />
                        </div>
                        <div>
                            <img src="./images/slider/11.webp" />
                        </div>
                        <div>
                            <img src="./images/slider/12.webp" />
                        </div>
                        <div>
                            <img src="./images/slider/13.webp" />
                        </div>
                        <div>
                            <img src="./images/slider/14.webp" />
                        </div>
                        <div>
                            <img src="./images/slider/15.webp" />
                        </div>
                    </Slider>
                </div>
                <div>После финиша планируется афтепати</div>
            </div>
        </div>
    )
}