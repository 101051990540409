export function NightBlock(){
    return (
        <div className="night-block block">
            <div className="about-block">
                <h2>Огни ночного города</h2>
                <p>Спокойный и безопасный проезд по городу, частые остановки и ленивый ритм, чтобы не устать, милые маршалы, готовые прийти на помощь. Это всё не про огни ночного города. <br /><br />Город засыпает - просыпаются отбитые роллеры. Зашугать припозднившуюся машину? Легко! Пролететь по дороге со свистом? Да не вопрос! Не видно дорогу в темноте? Будто это остановит! <br /><br />Самая отбитая катка закрытия! Средне-высокий темп, весёлые спуски с горок, внезапные манёвры! <b>Требуется высокий уровень навыков</b></p>
                <div className="info">
                    <div>Место: <span style={{
                fontWeight: 'bold'
                    }}><a style={{
                        color: 'white'
                    }} href="https://go.2gis.com/61ea3" target='_blank'>Фонтан "Весенняя песнь</a></span></div>
                    <div>Время сбора: <span style={{
                        fontWeight: 'bold'
                    }}>21 сентября 23:45</span>
                    </div>
                    <div>Время старта: <span style={{
                        fontWeight: 'bold'
                    }}>22 сентября 00:00</span>
                    </div>
                    <div>Сложность: <span style={{
                        fontWeight: 'bold'
                    }}>4/5</span>
                    </div>
                    <div>Расстояние: <span style={{
                fontWeight: 'bold'
            }}>24 км</span>
            </div>
                </div>
                <p>P.S. с собой обязательно <b>фонарики</b></p>
            </div>
        </div>
    )
}