import logo from './logo.svg';
import './App.css';
import { Header } from './components/Header';
import { NtvBlock } from './components/NtvBlock';
import { GoldBlock } from './components/GoldBlock';
import { NightBlock } from './components/NightBlock';

function App() {
  return (
    <div className="App">
      {/* <Header /> */}
      <div className="main-block block">
        <div className="about-block">
          <h1>Закрытие сезона</h1>
          <p>
            20-22 сентября состоится закрытие роллер сезона в Уфе. Раздели с нами эти золотые дни
            опавшей листвы
          </p>
          <button
            onClick={() => {
              document.getElementById('ntv').scrollIntoView({ behavior: 'smooth' });
            }}>
            Узнать подробнее
          </button>
        </div>
      </div>
      <NtvBlock />
      <GoldBlock />
      <NightBlock />
    </div>
  );
}

export default App;
