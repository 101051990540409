export function NtvBlock(){
    return (
        <div className="fr-block block" id="ntv">
        <div className="about-block">
          <h2>Ночь тысячи велосипедистов</h2>
          <p>
            Ежегодное событие, в котором тысячи велосипедистов проезжают по перекрытым улицам
            черниковки. <br /><br />Собираемся дружной толпой и врываемся на танцпол мероприятия!
          </p>
          {/* <p></p> */}
          <div className="info">
            <div>Место: <span style={{
                fontWeight: 'bold'
            }}><a style={{
                color: 'white'
            }} href="https://go.2gis.com/n2uo2" target='_blank'>Скейтпарк кашкадана</a></span></div>
            <div>Время сбора: <span style={{
                fontWeight: 'bold'
            }}>20 сентября 19:00</span>
            </div>
            <div>Время старта: <span style={{
                fontWeight: 'bold'
            }}>20 сентября 19:30</span>
            </div>
            <div>Сложность: <span style={{
                fontWeight: 'bold'
            }}>2/5</span>
            </div>
            <div>Расстояние: <span style={{
                fontWeight: 'bold'
            }}>17 км</span>
            </div>
          </div>
          <p>Старт самого мероприятия в 21:00 на ДК Машиностроителей (сбор роллеров у <a style={{
            color: 'white'
          }} href="https://go.2gis.com/prozd" target="_blank">озера Тепличное</a>)</p>
          <p>После этого едем в бар <a style={{
            color: 'white'
          }} href="https://go.2gis.com/obxg7" target="_blank">Синебрюхов</a> к 23:00</p>
          <div>
          {/* <div className="info">
            <div>А когда? 20 сентября 21:00</div>
            <div>А где? ДК Машиностроителей</div>
          </div> */}
          </div>
        </div>
      </div>
    )
}